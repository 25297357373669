import router from "../routing/router";

// export the config you'd get here
// https://github.com/mib200/vue-gtm
export default {
  id: "GTM-TN3C9MN",
  vueRouter: router,
  debug: true,
  ignoredViews: [
    "changePassword",
    "resetPassword",
    "dashboard",
    "bookings",
    "booking",
    "vendors",
    "services",
    "categories",
    "profile",
    "settings",
    "customers",
    "customer",
    "orders",
    "order",
    "images",
    "Applications",
    "View Application"
  ]
};
