import { make } from "vuex-pathify";

const state = {
  step: 1,
  formattedAddress: undefined,
  longitude: undefined,
  latitude: undefined,
  category: undefined,
  serviceType: undefined,
  tier: undefined,
  editingService: 0,
  services: [],
  dates: [],
  times: [],
  vendors: [],
  durations: [],
  guests: [],
  guestToken: undefined,
  discount: 0,
  discountConditional: "",
  discountIsFlat: true,
  discountForOrder: true,
  discountDescription: "",
  couponUsed: undefined
};

const mutations = make.mutations(state);
const actions = make.actions(state);
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters: {}
};
